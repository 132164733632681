<template>
  <div>
    <top-nav :showLogo="false" :title="$t('裂变设置')"></top-nav>
    <div class="container">
      <div class="title">{{ $t('待设置关系ID') }}</div>
      <div class="subtitle">{{ $t('每日24点自动绑定') }}</div>

      <div class="info-cont">
        <div class="uid-list">
          <div v-for="(item,index) in  relationlist" :key="index">
            <div class="uid-item" v-if="relationFolding || index<3">
              <div class="uid">UID : {{ item.uid }}</div>
              <img @click="copyData(item.uid+'')" class="copy-icon" src="../../assets/image/rwa/relation/copy-icon.png">
            </div>
          </div>

          <div class="uid-item" style="border: none;" v-if="relationlist.length == 0">
            <div class="uid" style="float: none;text-align: center">
              {{ $t('暂无数据') }}
            </div>
          </div>
        </div>
        <div class="deployment-cont" v-if="relationlist.length > 3" @click="relationFolding = !relationFolding">
          <img class="lower-img" :style="{ transform : relationFolding ? 'rotate(180deg)' : 'rotate(0deg)' }"
               src="../../assets/image/rwa/relation/below-arrow.png">
          {{ relationFolding ? $t('折叠') : $t('展开') }}
        </div>
      </div>

      <div class="title">{{ $t('设置关系') }}</div>
      <div class="subtitle">{{ $t('非关系账户无法绑定') }}</div>
      <div class="info-cont">
        <div class="bind-pid">{{ $t('绑定的上级用户') }}</div>
        <div class="input-cont">
          <div class="input-title">{{ $t('上级') }}ID</div>

          <input type="text" @focus="promptPid=''" v-model="formData.pid"
                 :placeholder="$t('请输入')"/>

        </div>
        <div class="prompt-cont">
          <img class="lower-icon" src="../../assets/image/rwa/relation/lower-icon.png">
          {{ promptPid }}
          <!--          -->
        </div>
        <div class="input-cont">
          <div class="input-title">{{ $t('下级') }}ID</div>
          <input type="text" @focus="promptId=''" v-model="formData.id"
                 :placeholder="$t('请输入')"/>
        </div>
        <div class="prompt-cont" style="padding-bottom: 0">
          {{ promptId }}
        </div>
      </div>

    </div>

    <div class="bottom-cont">
      <div class="button-cont">
        <router-link to="relationRecord">
          <div class="order-but">
            <img class="order-icon" src="../../assets/image/rwa/order-icon.png"/>
          </div>
        </router-link>
        <div class="confirm-but" @click="binding">{{ $t('确定') }}</div>
      </div>
    </div>


    <div class="modal-cont" v-if="confirmModal">
      <div class="content-cont">
        <div class="title">{{ $t('是否') }}</div>
        <div class="info">
          ID: {{ formData.id }} {{ $t('绑定到') }}
          <br/>
          ID: {{ formData.pid }}{{ $t('下级') }}
        </div>
        <div class="but-cont">
          <div class="but-left" @click="confirmModal=false">{{ $t('取消') }}</div>
          <div class="but-right" @click="submitAdjustRelation">{{ $t('确定') }}</div>
        </div>
      </div>
    </div>

    <div class="operation-success" v-if="showOperationSuccess">
      <div class="success-info-cont">
        <div class="success-info">
          <div class="title">{{ $t('绑定成功') }}</div>
          <div class="text">
            ID: {{ formData.id }} {{ $t('绑定到') }}
            <br/>
            ID: {{ formData.pid }}{{ $t('下级') }}
          </div>
        </div>
        <img class="_bg" src="../../assets/image/index/success-bg.png">
        <div class="_but" @click="onShowOperationSuccess">
          {{ $t('确定') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from '@/components/topNav.vue'
import {gteUnbindRelation, postAdjustRelation} from "../../api";

export default {
  name: "relation",
  data() {
    return {
      relationlist: [],
      relationFolding: true,
      confirmModal: false,
      formData: {
        "id": "",
        "pid": ""
      },
      promptId: '',
      promptPid: '',
      showOperationSuccess: false,
    }
  },
  components: {TopNav},
  created() {
    this.gteUnbindRelation()
  },
  methods: {
    onShowOperationSuccess() {
      this.showOperationSuccess = !this.showOperationSuccess
      if (!this.showOperationSuccess) {
        this.gteUnbindRelation()
        this.formData = {
          "id": "",
          "pid": ""
        }
      }
    },
    emptyValue(key) {
      if (key == 'pid') {
        this.formData.pid = ''
      }
    },
    binding() {
      let that = this
      if (that.formData.pid.length == 0) {
        that.promptPid = that.$t('请输入正确的ID')
        return;
      } else if (that.formData.id.length == 0) {
        that.promptId = that.$t('请输入正确的ID')
        return;
      }
      that.confirmModal = true
    },
    submitAdjustRelation() {
      let that = this

      this.$toast.loading({
        message: '',
        duration: 0,
      });
      that.confirmModal = false
      postAdjustRelation(that.formData)
          .then(({data}) => {
            console.log(data);
            if (data.code == 200) {
              that.showOperationSuccess = true
            } else {
              setTimeout(() => {
                that.$toast(data.message);
              }, 500)
            }
          })
          .finally(() => {
            that.$toast.clear()
          })
    },

    gteUnbindRelation() {
      let that = this;
      this.$toast.loading({
        message: '',
        duration: 0,
      });
      gteUnbindRelation()
          .then(({data}) => {
            console.log(data);
            if (data.code == 200) {
              that.relationlist = data.data.relation_list
            }
          })
          .finally(() => {
            that.$toast.clear()
          })
    },
    copyData(text) {
      let that = this;
      if (text) {
        that.$copyText(text).then(
            function (e) {
              that.$toast(that.$t('复制成功'));
            }
        );
      }
    },
  }
}
</script>

<style scoped lang="less">
.container {
  margin: 0 1rem;
  padding-top: 1.3rem;
  padding-bottom: 5rem;

  .title {
    width: 90%;
    margin: auto;
    font-size: 1rem;
    color: #E7BD71;
    padding-bottom: 0.5rem;
  }

  .subtitle {
    width: 90%;
    margin: auto;
    font-size: 0.79rem;
    color: #6E6045;
  }

  .info-cont {
    background: #1B1B1B;
    margin-top: 1rem;
    padding: 1rem 1.25rem;
    border-radius: 1rem;
    margin-bottom: 1.6rem;
  }

  .uid-list {
    .uid-item {
      overflow: hidden;
      padding: 1rem 0;
      border-bottom: 0.04rem solid #262526;

      .uid {
        font-size: 0.93rem;
        color: #E7BD71;
        float: left;
      }

      .copy-icon {
        float: right;
        width: 1.14rem;
      }
    }
  }

  .bind-pid {
    width: 90%;
    margin: 0.68rem auto;
    text-align: right;
    font-size: 0.79rem;
    color: #625F5F;
  }

  .input-cont {
    padding: 1rem 1.4rem;
    background: #101010;
    overflow: hidden;
    border-radius: 0.71rem;

    .input-title {
      float: left;
      color: #6E6045;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    input {
      width: 65%;
      float: right;
      background-color: transparent;
      border: none;
      text-align: right;
    }

    input::-webkit-input-placeholder {
      color: #6E6045;
    }

    .empty-icon {
      margin-left: 0.2rem;
      float: right;
      width: 1.29rem;
    }
  }

  .prompt-cont {
    position: relative;
    width: 90%;
    height: 1.5rem;
    margin: auto;
    text-align: right;
    padding: 0.43rem 0;
    font-size: 0.86rem;
    color: #E64937;
    line-height: 1.5rem;
  }

  .lower-icon {
    position: absolute;
    width: 0.25rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .deployment-cont {
    display: flex;
    padding-top: 1.2rem;
    align-items: center;
    justify-content: center;
    font-size: 0.93rem;
    color: #987534;

    .lower-img {
      height: 0.43rem;
      margin-right: 0.2rem;
    }
  }
}


.button-cont {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  overflow: hidden;
  padding: 0.9rem 1rem;
  box-sizing: border-box;
  background: #151515;
  color: #000;
  z-index: 2;

  .order-but {
    float: left;
    display: flex;
    width: 20%;
    height: 3.07rem;
    align-items: center;
    justify-content: center;
    background: #40341D;
    border-radius: 1.54rem;

    .order-icon {
      width: 1.5rem;
    }
  }

  .confirm-but {
    float: right;
    text-align: center;
    width: 75%;
    height: 3.07rem;
    font-size: 1.29rem;
    text-align: center;
    font-weight: 600;
    line-height: 3.07rem;
    background: linear-gradient(270deg, #E8BD70 0%, #EDD185 100%);
    border-radius: 1.54rem;
  }
}


.operation-success {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;

  .success-info-cont {
    position: relative;
    width: 70vw;
    margin: auto;
    border-radius: 1.07rem;

    ._bg {
      width: 100%;
    }

    .success-info {
      position: absolute;
      top: 20%;
      left: 0;
      right: 0;
      width: 90%;
      margin: auto;
      text-align: center;

      .title {
        font-size: 1.14rem;
        font-weight: bold;
        color: #E7BD71;
        padding-bottom: 1.1rem;
      }

      .text {
        font-size: 1.14rem;
        color: #E7BD71;
      }


    }

    ._but {
      position: absolute;
      bottom: 2.1rem;
      left: 0;
      right: 0;
      margin: auto;
      width: 85%;
      line-height: 2.5rem;
      color: #000;
      text-align: center;
      font-size: 1.14rem;
      background: linear-gradient(270deg, #E8BD70 0%, #EDD185 100%);
      border-radius: 1.43rem;
    }
  }
}
</style>
